export const User_root = "/";
export const feature = "/#feature-tab";
export const free_trail = "/freeTrial";
export const join = "/join";
export const loginurl = "/login";
export const set_password = "/set-password";
export const change_password = "/change-password";
export const signupurl = "/additionalInformation";
export const free_output = "/freeOutput";
export const term_condition = "/term-conditions";
export const privacy_policy = "/privacy-policy";
export const contact_us = "/contact-us";
export const blog = "/blog";
export const profile = "/profile";
export const stagewise_loading = "/stagewise-loading";
export const admin_setting = "/admin/setting";
export const admin_manageuser = "/admin/manage-user";
export const planner_order = "/planner/order";
export const planner_skuSelection = "/planner/order-details";
export const planner_contSelection = "/planner/create-load";
export const new_user = "/new-user";
